import React,{useEffect, useState} from 'react'
import { CustomTextField } from 'elements';
import { Button,Link,Grid,Divider,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { useFormik } from "formik";
import {  string } from "yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux"
import { loginWithJWT, accessRoot, accessRootOTP, verifyaccessRootOTP } from "../../redux/actions/auth/loginActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import Modals from 'components/Modal/Modal';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';


const useStyles = makeStyles(styles)

export default function SignInForm() {
  const { loading, error, user, errorStatus, accessrootuser, accessrooterrorStatus,
    checkingroototpuser, checkingroototperrorStatus } = useSelector((state) => state.auth.login);
  const classes = useStyles()
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {t} = useTranslation('common');
  const [showNewEmailTextBox, setshowNewEmailTextBox] = useState(false); // Step 2: State for showing new text box
  const [isEmail, setAccessEmail] = useState(false);
  const [isOtp, setAccessOtp] = useState(false);
  const [userStatusOtpBox, setuserStatusOtpBox] = useState(false);
  const [userStatus, setuserStatus] = useState(null);
  const [RootValue, setRootValue] = useState(false);
  const [ActionValue, setActionValue] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [timeLeft, setTimeLeft] = useState(); // 5 minutes in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => Math.max(prevTime - 1, 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {  
    if(error){
      Swal.fire({
        title: t('signIn.failed'),
        text: error === "Entered Email/Password is incorrect" ? t('signIn.inCorrectError'): t('signIn.noActiveAccount'),
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',
        confirmButtonText: t('companyAdd.close')
      })
      dispatch({
        type: "LOGIN_WITH_EMAIL_FAILED",
        payload: null
      })
    }    
  }, [error]);

  const formik = useFormik({
    initialValues: {    
      email: "",    
      password: "",
      yourEmail:"",
      otp:""    
    },
    validationSchema: Yup.object({    
      email: string().required(t('signIn.emailValidation')).email(t('signIn.Invalidemail')),    
      password: string().required(t('signIn.passwordValidation')),
      isEmail: Yup.boolean(),
      yourEmail: isEmail ? Yup.string().email(t('signIn.InvalidYourEmail')).required(t('signIn.YourEmailValidation')) : Yup.string().email(t('signIn.InvalidYourEmail')),
      isOTP: Yup.boolean(),
      otp: isOtp ? Yup.string().required(t('signIn.otpValidation')).matches(/^\d{4}$/, t('signIn.InvalidOtp')) : Yup.string().matches(/^\d{4}$/, t('signIn.InvalidOtp')),
    }),
    onSubmit : (values, formikHelpers)=> {
      if (values.email !== ''){
        if (RootValue !== true){
          dispatch(accessRoot(values));
          setRootValue(true);
          setAccessEmail(true);
        }
        if (values.email !== '' && values.password !== '' && values.otp === '' && values.email === accessrootuser.data.email && RootValue === true){
          dispatch(verifyaccessRootOTP(values));
          // update setuserStatus
          setActionValue(true);
          
        }
        if (values.email !== '' && values.password !== '' && values.otp !== '' 
          && values.email === accessrootuser.data.email && RootValue === true && 
          values.yourEmail === checkingroototpuser.data.user_email && 
          checkingroototpuser.data.user_status === 'active'){
            dispatch(loginWithJWT(values));
            formik.setFieldValue('otp', '');
            // setuserStatusOtpBox(false);
        }
        if (values.email !== '' && values.password !== '' && values.email !== accessrootuser.data.email ){
            setRootValue(false);
            setshowNewEmailTextBox(false);
            setAccessEmail(false);
            setuserStatusOtpBox(false);
            setAccessOtp(false);
            setOpen(false);
            dispatch(loginWithJWT(values)) 
        }
      }   
    }
  });

  useEffect(() => {
    if (formik.values.email !== '' && formik.values.password !== '' && formik.values.otp === '' && formik.values.email === accessrootuser.data.email && RootValue === true){
      setshowNewEmailTextBox(true);
    }
  })

  useEffect(() => {
    if(ActionValue === true){
      setuserStatus(checkingroototpuser.data.user_status);
      if (formik.values.yourEmail === checkingroototpuser.data.user_email && userStatus === 'created'){
        setuserStatusOtpBox(false);
        setOpen(true);
        formik.setFieldValue('otp', '');
      }
      else if (formik.values.yourEmail === checkingroototpuser.data.user_email && userStatus === 'pending'){
        setuserStatusOtpBox(false);
        setAccessOtp(false);
        setOpen(true);
        formik.setFieldValue('otp', '');
      }
      else if(formik.values.yourEmail === checkingroototpuser.data.user_email && userStatus === 'active'){
          setuserStatusOtpBox(true);
          setAccessOtp(true);
          dispatch(accessRootOTP(formik.values));
          setTimeLeft(300);
          formik.setFieldValue('otp', '');
        }
    }
  },[checkingroototpuser, userStatus]);

  const handleResendOTP = () => {
    // Your resend OTP logic here
    formik.setFieldValue('otp', '');
    dispatch(accessRootOTP(formik.values));
    setTimeLeft(300);
  };

  useEffect(() => {
    if(user?.is_superuser){
      navigate("/dashboard/xdata/buckets")
    }else if(user?.is_workspace_admin){
      navigate("/dashboard/workspace-users")
    }else if(user?.is_colordb_editor){
      navigate("/dashboard/xdata/buckets")
    }else if(user?.is_historians){
      navigate("/dashboard/xdata/analytics")
    }else if(user?.is_theme_editor){
      navigate("/dashboard/themes")
    }else if(user?.is_supervisor){
      navigate("/dashboard/root-access-list")
    }
  }, [user]);

  useEffect(() => {  
    if(errorStatus === 0|| errorStatus === 500){
      Swal.fire({
        title: 'Failed',
        text: 'Login Failed',
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "LOGIN_WITH_EMAIL_FAILED_STATUS",
        payload: null
      })
    }

    // if(accessrooterrorStatus === 0|| accessrooterrorStatus === 500 || accessrooterrorStatus === 400){
    //   dispatch(loginWithJWT(formik.values))
    // }

    if(checkingroototperrorStatus === 0|| checkingroototperrorStatus === 500 || checkingroototperrorStatus === 400){
      Swal.fire({
        title: 'Failed',
        text: 'Invalid User',
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "CHECK_OTP_FOR_ROOT_FAILED_STATUS",
        payload: null
      })
    } 

  }, [errorStatus, accessrooterrorStatus, checkingroototperrorStatus]);

  useEffect(() => {  
    dispatch({
      type: "INITIAL_STATE",
      payload: null
    })
  }, []);

  const bigHtmlContent = (
    <>
      <div className={classes.logomodel}>
        { userStatus === 'pending' &&
          <>
          <PendingActionsIcon color="error" style={{ width: '100px', height: '100px' }} />
         </>
        }
        { userStatus === 'created' &&
          <>
          <MarkEmailReadIcon color="error" style={{ width: '100px', height: '100px' }} />
         </>
        }
      </div>
      <div className={classes.textmodel}> 
        { userStatus === 'pending' &&
          <>
            <div>
                <Typography className={classes.type} id="modal-modal-title" variant="h6" component="h2">
                {t('signIn.successMessage2')}
                </Typography>
            </div>

            <div >
                <Typography id="modal-modal-subtitle" sx={{  color: "#7D7D7D"}}>
                {t('signIn.pendingMessage')}
                </Typography>
            </div>

            <div >
                <Typography id="modal-modal-subtitle" sx={{  color: "#C8102E"}}>
                {t('signIn.contactCorobITDepartmentHead')} 
                </Typography>
            </div>
         </>
        }

        { userStatus === 'created' &&
          <>
            <div>
                <Typography className={classes.type} id="modal-modal-title" variant="h6" component="h2">
                {t('signIn.successMessage1')}
                </Typography>
            </div>

            <div >
                <Typography id="modal-modal-subtitle" sx={{  color: "#7D7D7D"}}>
                {t('signIn.createdMessage')}
                </Typography>
            </div>
         </>
        }     
            

      </div>
      <div className={classes.buttonmodel}>
        <Button
        variant="contained" 
        onClick={() => {navigate("/sign-in");  window.location.reload(); 
            setRootValue(false);
            setshowNewEmailTextBox(false);
            setAccessEmail(false);
            setAccessOtp(false);
            setOpen(false);
        }}
        size="medium">
          {t('signUp.goBack')}
        </Button>
      </div> 

    </>
);


  return (
   <React.Fragment>
      <div className={classes.heading}>{t('signIn.title')}</div>
      <Divider classes={{root:classes.subheading,wrapper:classes.divider}} >{t('signIn.subTitle')}</Divider>
      <form onSubmit={formik.handleSubmit}>
        <div  className="public-textbox">
       <CustomTextField 
          // required="false"
          label={t('signIn.email')}
          name="email"
          type="email" 
          placeholder={t('signIn.email')}
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.email} 
          error = {formik.touched.email && Boolean(formik.errors.email)} 
          helperText = {formik.touched.email && formik.errors.email}
          login={true}
          disableField={showNewEmailTextBox}
        />
        <CustomTextField 
          label={t('signIn.password')}
          name="password"
          type="password" 
          placeholder={t('signIn.password')}
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.password} 
          error = {formik.touched.password && Boolean(formik.errors.password)} 
          helperText = {formik.touched.password && formik.errors.password}
          login={true}
          disableField={showNewEmailTextBox}
        />
        {showNewEmailTextBox && (
          <CustomTextField 
          label={t('signIn.YourEmail')}
          name="yourEmail"
          type="yourEmail" 
          placeholder={t('signIn.YourEmail')}
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.yourEmail} 
          error = {formik.touched.yourEmail && Boolean(formik.errors.yourEmail)} 
          helperText = {formik.touched.yourEmail && formik.errors.yourEmail}
          login={true}
          disableField={userStatusOtpBox}/>
        )}
        {userStatusOtpBox && (
          <CustomTextField 
          label={t('signIn.OTP')}
          name="otp"
          type="otp" 
          placeholder={t('signIn.OTP')}
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.otp} 
          error = {formik.touched.otp && Boolean(formik.errors.otp)} 
          helperText = {formik.touched.otp && Boolean(formik.errors.otp)}
          login={true}/>
        )}
        </div>
        <Grid  className={classes.down} container spacing={2} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={6} textAlign="left">
            {userStatusOtpBox && timeLeft !== 0 && (
              <p className={classes.first}>Time left: {formatTime(timeLeft)} </p>
            )}
            {userStatusOtpBox && timeLeft === 0 && (
              <Link className={classes.link} color="#FFFFFF" sx={{ cursor: 'pointer' }} onClick={handleResendOTP}>Resend OTP</Link>
            )}
          </Grid>
          
          <Grid item xs={6} textAlign="right">
          {showNewEmailTextBox === false && (
            <Link className={classes.link} color="#FFFFFF" href="/forgot-password" >{t('signIn.forgotYourPassword')}</Link>
          )}
          {showNewEmailTextBox && (
            <Link className={classes.link} color="#FFFFFF" sx={{ cursor: 'pointer' }} 
            onClick={() => {navigate("/sign-in");  window.location.reload();}}>{t('signIn.loginmyself')}</Link>
          )}
          </Grid>

        </Grid>
        <Button type="submit" className={classes.button}>
          {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('signIn.login')}
        </Button>
      </form>
      <div className={classes.bottom}>
        <p className={classes.first}>{t('signIn.notRegisteredYet')} <Link  href="/sign-up" sx={{ "&:hover": { color: "#FFFFFF" },opacity:1}} color="#FFFFFF" >{t('signIn.createAnAccount')}</Link></p> 
      </div> 
      <Modals handleclose={handleClose} open={open} content={bigHtmlContent} />
   </React.Fragment>
  )
}
